@import "./tachyons.css";

h1,
h2,
h3,
h4,
figure,
ol,
blockquote > p {
  margin: 0;
}
p,
blockquote {
  margin: 1rem 0;
}
/* Font styles */
body,
input {
  font-family: proxima-nova-soft, sans-serif;
  -webkit-font-smoothing: antialiased;
}
/* Tachyons Overrides & Additions */
.blue {
  color: #00b7dd;
}

.bg-blue {
  background-color: #00b7dd;
}

.gold {
  color: #f4c52b;
}

.bg-gold {
  background-color: #f4c52b;
}

.green {
  color: #56cc91;
}

.bg-green {
  background-color: #56cc91;
}

.bg-amber {
  background-color: #fa992d;
}

.bg-light-gray {
  background-color: #eeeeee;
}

.measure-ultrawide {
  max-width: 75rem;
}

.tracked-tight {
  letter-spacing: -0.01em;
}

.w-icon {
  max-width: 100px;
}

.w-50--gutter {
  width: calc(50% - 0.5rem);
}

@media (min-width: 45em) {
  .w-50--gutter-l {
    width: calc(50% - 0.5rem);
  }
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.convex {
  padding-bottom: 2rem;
}
.convex + .convex {
  padding-top: 2rem;
}
.convex::after {
  content: "";
  display: block;
  width: 110%;
  height: 4rem;
  position: absolute;
  bottom: -2rem;
  left: -5%;
  border-radius: 100%;
  z-index: 10;
  background-color: inherit;
}

.bg-purple-gradient {
  background: linear-gradient(#9122b3, #6e38a8);
}
.bg-purple-gradient::after {
  background-color: #6e38a8;
}

.bg-gold-gradient {
  background: linear-gradient(#fbb11c, #fccb2a);
}
.bg-gold-gradient::after {
  background-color: #fccb2a;
}

.bg-green-gradient {
  background: linear-gradient(#66ccbb, #56cc91);
}
.bg-green-gradient::after {
  background-color: #56cc91;
}

.markdown + .markdown {
  border-top: 1pt solid rgba(0, 0, 0, 0.1);
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
  font-weight: 600;
  line-height: 1.25;
  margin-top: 1.25em;
}
.markdown * + .markdown h1,
.markdown * + .markdown h2,
.markdown * + .markdown h3,
.markdown * + .markdown h4 {
  margin-top: 2em;
}
.markdown h1 {
  font-size: 1.5em;
}
.markdown > p,
.markdown > ul {
  opacity: 0.8;
}
.markdown a {
  color: #56cc91; /* GREEN */
}
.markdown blockquote {
  font-size: 1.25em;
  font-weight: 500;
  color: #777;
  background-color: #f4f4f4;
  padding: 1em;
  border-radius: 0.5em;
}
.markdown hr {
  display: none;
}
.markdown img {
  border-radius: 0.5rem;
  overflow: hidden;
}
.footnotes {
  font-size: 1rem;
}
.footnote-ref a,
.footnotes a {
  color: #5bb6a7;
  text-decoration: none;
}
.footnotes-list {
  padding-left: 1.5rem;
}

.markdown.cta a {
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid;
}

.markdown.cta a::after {
  content: "";
  display: block;
  position: absolute;
  right: -0.75em;
  bottom: 8px;
  width: 9px;
  height: 16px;
  background-image: url("../assets/img/chevron-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transition-property: transform;
}

.markdown.cta:hover a::after {
  -webkit-animation-name: cta-hover;
  animation-name: cta-hover;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@-webkit-keyframes cta-hover {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.AppStoreButton {
  background: url("../assets/img/appstore.svg") no-repeat;
  width: 226px;
  height: 68px;
  background-size: contain;
}

.slideshow {
  position: relative;
}

.slideshow img {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}
/* targets all images after the first */
.slideshow > img:nth-child(n + 2) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.Card {
  border-radius: 1rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.02);
  border: 2px solid rgba(0, 0, 0, 0.04);
}

.HorizontalScrollList {
  max-width: 100%;
  padding: 0;
  list-style: none;
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.HorizontalScrollList::-webkit-scrollbar {
  display: none;
}

.HorizontalScrollList > li {
  flex: 0 0 auto;
}

/*
  ANIMATIONS
  via Animate.css by Daniel Eden
*/

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
